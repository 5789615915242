export const NEWS_POST_TYPES = [
  'article',
  'review',
  'liveklassisk',
  'news',
  'discussion',
  'interview',
  'advertisement'
]

export const NEWS_POST_COLOURS = {
  article: '#008381',
  review: '#CA4E20',
  liveklassisk: '#9A6548',
  news: '#51387B',
  discussion: '#EF6C00',
  interview: '#9D155C',
  advertisement: '#D84315'
}
