import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { Ensemble } from '../../types/types'
import { getAllDocuments, Indexed } from '../meilisearch'
import { generateEnsembleSlug } from '../slugs'

export const useEnsembleSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<Ensemble>(filters, 'ensembles', query, numHits)

export const generateEnsembleId = (
  firestore: ExtendedFirestoreInstance
): string => firestore.collection('ensembles').doc().id

export const createEnsemble = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Ensemble, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  ensembleId: string
): Promise<void> => {
  const slug = generateEnsembleSlug(data.name)
  await firestore
    .collection('ensembles')
    .doc(ensembleId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getEnsembleDoc = async (
  firestore: ExtendedFirestoreInstance,
  ensembleId: string
): Promise<firebase.firestore.DocumentSnapshot<Ensemble>> => {
  const ensembleDoc = await firestore
    .collection('ensembles')
    .doc(ensembleId)
    .get()
  return ensembleDoc as firebase.firestore.DocumentSnapshot<Ensemble>
}

export const updateEnsemble = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<Ensemble>,
  ensembleId: string
): Promise<void> => {
  await firestore
    .collection('ensembles')
    .doc(ensembleId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteEnsemble = async (
  firestore: ExtendedFirestoreInstance,
  ensembleId: string
): Promise<void> => {
  await firestore.collection('ensembles').doc(ensembleId).update({
    isDeleted: true
  })
}

export const getAllPublicEnsembles = async () => {
  const ensembles = await getAllDocuments<
    Indexed<Ensemble> & { countryCode: string }
  >('ensembles')

  return ensembles.filter(
    (ensemble) => ensemble.isDeleted === false && ensemble.isOverview === true
  )
}
