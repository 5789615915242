import { makeStyles } from '@material-ui/styles'
import Link from 'next/link'
import { useEffect } from 'react'
import { useFirestore } from 'react-redux-firebase'
import {
  incremenetAdClickCount,
  incremenetAdViewCount,
  useRandomAd
} from '../../utils/api/ads'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '235px',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 3px 11px #08135929'
  },
  '@media screen and (max-width: 764px)': {
    root: {
      height: '235px'
    }
  },
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))

const ConcertCardSmAd: React.FC = () => {
  const classes = useStyles()

  const ad = useRandomAd('concertcard-sm')

  const firestore = useFirestore()

  useEffect(() => {
    if (ad?.id) {
      incremenetAdViewCount(firestore, ad.id)
    }
  }, [ad?.id])

  if (!ad) {
    return null
  }

  return (
    <Link href={ad.href}>
      <a target='_blank'>
        <div
          className={classes.root}
          onClick={() => incremenetAdClickCount(firestore, ad.id)}
        >
          <img alt={ad.title} src={ad.imageUrl} className={classes.img} />
        </div>
      </a>
    </Link>
  )
}

export default ConcertCardSmAd
