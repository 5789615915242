import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@material-ui/core'
import { NEWS_POST_PATH } from '../constants/paths'
import Link from 'next/link'
import { NewsPost } from '../types/types'
import { getThumbnailUrl } from '../utils/thumbnail'
import { useRegion } from '../hooks/useRegion'
import { useTranslation } from 'next-i18next'
import Moment from 'react-moment'
import NewsTypeChip from './NewsTypeChip'

const useStyles = makeStyles((theme) => ({
  media: {
    height: '200px'
  },
  cardRoot: {
    minHeight: '100%',
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    height: 70,
    fontWeight: 'bold',
    color: '#081359',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: {
      height: 55,
      fontSize: '1.25rem'
    }
  }
}))

type NewsCardProps = {
  post: NewsPost
  className?: string
}

const NewsCard: React.VFC<NewsCardProps> = ({ post, className }) => {
  const classes = useStyles()
  const region = useRegion()
  const { t } = useTranslation()

  return (
    <Link href={NEWS_POST_PATH(region, post.slug)} passHref>
      <a>
        <Card className={`${classes.cardRoot} ${className}`}>
          <CardMedia
            className={classes.media}
            image={getThumbnailUrl(post.coverUrl)}
            title={post.title}
          />
          <CardContent>
            <NewsTypeChip type={post.type} />
            <Typography className={classes.title} variant='h5' component='h1'>
              {post.title}
            </Typography>
            <Grid
              container
              justifyContent='space-between'
              alignItems='flex-end'
            >
              <Grid item xs>
                <div>
                  <Moment
                    unix
                    date={post?.publishedAt ?? post.createdAt}
                    format='D. MMM YYYY'
                  />
                </div>
                <div>
                  <span>
                    {t('by')} {post.username}
                  </span>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </a>
    </Link>
  )
}

export default NewsCard
