import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { NewsPost } from '../../types/types'
import { getAllDocuments, Indexed } from '../meilisearch'
import { generateNewsPostSlug } from '../slugs'

export const useNewsSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<NewsPost>(filters, 'news', query, numHits)

export const generateNewsPostId = (
  firestore: ExtendedFirestoreInstance
): string => firestore.collection('news').doc().id

export const createNewsPost = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<NewsPost, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  postId: string
): Promise<void> => {
  const slug = generateNewsPostSlug(data.title)
  await firestore
    .collection('news')
    .doc(postId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getNewsPostDoc = async (
  firestore: ExtendedFirestoreInstance,
  postId: string
): Promise<firebase.firestore.DocumentSnapshot<NewsPost>> => {
  const newsPostDoc = await firestore.collection('news').doc(postId).get()
  return newsPostDoc as firebase.firestore.DocumentSnapshot<NewsPost>
}

export const updateNewsPost = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<NewsPost>,
  postId: string
): Promise<void> => {
  await firestore
    .collection('news')
    .doc(postId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteNewsPost = async (
  firestore: ExtendedFirestoreInstance,
  postId: string
): Promise<void> => {
  await firestore.collection('news').doc(postId).update({
    isDeleted: true
  })
}

export const getAllPublicNewsPosts = async () => {
  const posts = await getAllDocuments<Indexed<NewsPost>>('news')

  return posts.filter((post) => post.isDeleted === false)
}
