import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import CardAd from '../../components/ads/CardAd'
import ConcertCardAd from '../../components/ads/ConcertCardAd'
import LgAd from '../../components/ads/LgAd'
import MdAd from '../../components/ads/MdAd'
import SmAd from '../../components/ads/SmAd'
import ConcertCard from '../../components/ConcertCard'
import ConcertSearchBar from '../../components/ConcertSearchBar'
import ConcertseriesCard from '../../components/ConcertseriesCard'
import EnsembleCard from '../../components/EnsembleCard'
import FestivalCard from '../../components/FestivalCard'
import { FrontpagePopup } from '../../components/FrontpagePopup'
import { SearchLocation } from '../../components/LocationPopover'
import NewsCard from '../../components/NewsCard'
import Newsletter from '../../components/Newsletter'
import ScrollToTopButton from '../../components/layout/ScrollToTopButton'
import VenueCard from '../../components/VenueCard'
import {
  CONCERTSERIES_LIST_PATH,
  CONCERT_LIST_PATH,
  ENSEMBLE_LIST_PATH,
  FESTIVAL_LIST_PATH,
  HOME_PATH,
  NEWS_PATH,
  VENUE_LIST_PATH
} from '../../constants/paths'
import { localizedCountryName } from '../../constants/translationMethods'
import { useCurrentDate } from '../../hooks/useCurrentDate'
import useDebounce from '../../hooks/useDebounce'
import { useRegion } from '../../hooks/useRegion'
import { getAbsoluteUrl } from '../../utils/absoluteUrl'
import { useConcertSearch } from '../../utils/api/concerts'
import { useConcertseriesSearch } from '../../utils/api/concertseries'
import { useEnsembleSearch } from '../../utils/api/ensembles'
import { useFestivalSearch } from '../../utils/api/festivals'
import { useNewsSearch } from '../../utils/api/news'
import { useVenueSearch } from '../../utils/api/venues'
import { getRegion } from '../../utils/locationFunctions'
import {
  defaultGetStaticPaths,
  getLocaleFromContext
} from '../../utils/staticGeneration'
import { dateToSeconds } from '../../utils/time'
import {
  encodeSearchParams,
  extractSearchParamsFromURL
} from '../../utils/urlParams'
import ConcertCardSmAd from '../../components/ads/ConcertCardSmAd'

// Default static site generation.
export const getStaticPaths = defaultGetStaticPaths
export const getStaticProps: GetStaticProps = async (context) => ({
  props: {
    ...(await serverSideTranslations(getLocaleFromContext(context), ['common']))
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6)
  },
  '@media screen and (max-width: 600px)': {
    root: {
      paddingTop: theme.spacing(2)
    }
  },
  title: {
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    letterSpacing: 1.5
  },
  paddingBottom4: {
    paddingBottom: theme.spacing(4)
  },
  marginBottom8: {
    marginBottom: theme.spacing(8)
  },
  button: {
    fontWeight: 700,
    borderRadius: 8
  },
  sidewaysScrollContainer: {
    [theme.breakpoints.down('md')]: {
      flexWrap: 'nowrap',
      overflow: 'auto'
    }
  },
  sidewaysScrollItem: {
    [theme.breakpoints.down('md')]: {
      width: '270px',
      height: '365px'
    }
  },
  newsCard: {
    height: 382,
    [theme.breakpoints.down('md')]: {
      height: 362
    }
  }
}))

const constructConcertFilters = (
  region: string,
  date: Date,
  types: string[],
  location?: SearchLocation
): string => {
  // Static filters
  const hideDeletedFilter = 'isDeleted=false'
  // const hideInactiveFilter = 'user.status=active'
  const regionFilter = `countryCode=${region}`
  const staticFilters = [hideDeletedFilter, regionFilter]

  // Timestamp filters
  const dateFilters = [`dateTime >= ${dateToSeconds(date)}`]

  // Location filters
  const regionValues =
    location && getRegion(location.lat, location.lng, location.radius / 1000)
  const locationFilters = location
    ? [
        `lat > ${regionValues.N}`,
        `lat < ${regionValues.S}`,
        `lng > ${regionValues.W}`,
        `lng < ${regionValues.E}`
      ]
    : []

  // Type filters
  const typeFilters =
    types.length > 0
      ? ['(' + types.map((type) => `types=${type}`).join(' OR ') + ')']
      : []
  // Combine filters
  const filterList = [
    ...staticFilters,
    ...dateFilters,
    ...locationFilters,
    ...typeFilters
  ]
  return filterList.join(' AND ')
}

const FrontPage: NextPage = () => {
  const region = useRegion()
  const { t } = useTranslation('common')

  const router = useRouter()
  const now = useCurrentDate()

  const classes = useStyles()

  // To keep typing responsive, we store information in state.
  const [query, setQuery] = useState('')
  const [date, setDate] = useState<Date | undefined>()
  const [location, setLocation] = useState<SearchLocation | undefined>()
  const [types, setTypes] = useState<string[]>([])

  // Extract filters from URL.
  const [hasExtractedQueryFromURL, setHasExtractedQueryFromURL] =
    useState(false)

  useEffect(() => {
    // We only do this once.

    if (!hasExtractedQueryFromURL && router.isReady) {
      setHasExtractedQueryFromURL(true)
      const info = extractSearchParamsFromURL(router.query)
      const { query, types, date, location } = info
      setQuery(query)
      setTypes(types)
      setDate(date)
      setLocation(location)
    }
  }, [router, hasExtractedQueryFromURL])

  const debouncedQuery = useDebounce(query, 200)

  const queryParams = useMemo(
    () =>
      encodeSearchParams({
        query: debouncedQuery,
        types,
        date,
        location,
        region
      }),
    [debouncedQuery, date, location, types, region]
  )

  useEffect(() => {
    if (router.isReady) {
      router.replace(
        { query: { ...router.query, ...queryParams } },
        undefined,
        { shallow: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  // Construct filters
  const filters = constructConcertFilters(region, date ?? now, types, location)

  // Use Search hook with query params
  const concertsSearch = useConcertSearch(filters, query, 6)

  const newsSearch = useNewsSearch(
    `languages=${region} AND isDeleted=false AND isPublished=true AND isFeatured=true`,
    '',
    6
  )
  const featuredFestivalSearch = useFestivalSearch(
    `countryCode=${region} AND isDeleted=false AND isFeatured=true AND end >= ${dateToSeconds(
      date ?? now
    )}`,
    '',
    8
  )
  const nonFeaturedFestivalSearch = useFestivalSearch(
    `countryCode=${region} AND isDeleted=false AND isFeatured=false AND end >= ${dateToSeconds(
      date ?? now
    )}`,
    '',
    8
  )
  // Include non-featured festivals as well, to make sure that there are always 8 festivals shown.
  const festivalHits = [
    ...featuredFestivalSearch.hits,
    ...nonFeaturedFestivalSearch.hits
  ]
  const concertseriesSearch = useConcertseriesSearch(
    `countryCode=${region} AND isDeleted=false AND isFeatured=true`,
    '',
    8
  )
  const ensembleSearch = useEnsembleSearch(
    `countryCode=${region} AND isDeleted=false AND isFeatured=true`,
    '',
    8
  )
  const venueSearch = useVenueSearch(
    `countryCode=${region} AND isDeleted=false AND isFeatured=true`,
    '',
    8
  )

  return (
    <>
      <Head>
        <title>
          {t('Classical concerts in')} {t(localizedCountryName(region))} - Live
          Klassisk
        </title>
        <meta
          name='description'
          content={t(
            'Live Klassisk – classical concerts, festivals, ensembles and venues in Scandinavia. Discover classical music with all the greatest artists in the world!'
          )}
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/liveklassisk.appspot.com/o/uploads%2Flogo%2Fliveklassisk-logo_500x500.jpg?alt=media&token=084060e9-4674-4583-bd40-09cc597c149a'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={getAbsoluteUrl(HOME_PATH(region))} />
        <meta property='og:title' content='Live Klassisk' />
        <link rel='canonical' href={getAbsoluteUrl(HOME_PATH(region))} />
      </Head>
      <Container className={classes.root}>
        {/** CONCERTS */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Upcoming concerts')}
        </Typography>

        <ConcertSearchBar
          query={query}
          setQuery={setQuery}
          types={types}
          setTypes={setTypes}
          date={date}
          setDate={setDate}
          location={location}
          setLocation={setLocation}
        />

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Grid item xs={12} lg={9}>
            <Grid container direction='column' spacing={3}>
              {concertsSearch.hits.slice(0, 4).map((concert) => (
                <Grid key={concert.objectID} item>
                  <ConcertCard concertId={concert.objectID} concert={concert} />
                </Grid>
              ))}

              <Hidden lgUp xsDown>
                <Grid item xs={12}>
                  <ConcertCardAd />
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item xs={12}>
                  <ConcertCardSmAd />
                </Grid>
              </Hidden>

              <Hidden mdDown>
                {concertsSearch.hits.slice(4).map((concert) => (
                  <Grid key={concert.objectID} item>
                    <ConcertCard
                      concertId={concert.objectID}
                      concert={concert}
                    />
                  </Grid>
                ))}
              </Hidden>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={3}>
              <Grid container direction='column' spacing={3}>
                <Grid item xs={12}>
                  <SmAd />
                </Grid>
                <Grid item xs={12}>
                  <SmAd />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          {/* Link to concerts page persists search query parameters */}
          <Link
            href={{ pathname: CONCERT_LIST_PATH(region), query: router.query }}
          >
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all concerts')}
              </Button>
            </a>
          </Link>
        </Grid>

        {/** NEWS */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Top news and articles')}
        </Typography>

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Grid item xs lg={9}>
            <Grid
              container
              spacing={3}
              className={classes.sidewaysScrollContainer}
            >
              {newsSearch.hits.slice(0, 3).map((post) => (
                <Grid key={post.objectID} item xs lg={4}>
                  <div className={classes.sidewaysScrollItem}>
                    <NewsCard post={post} className={classes.newsCard} />
                  </div>
                </Grid>
              ))}
              <Hidden lgUp>
                <Grid item xs lg={4}>
                  <div className={classes.sidewaysScrollItem}>
                    <CardAd />
                  </div>
                </Grid>
              </Hidden>
              {newsSearch.hits.slice(3).map((post) => (
                <Grid key={post.objectID} item xs lg={4}>
                  <div className={classes.sidewaysScrollItem}>
                    <NewsCard post={post} className={classes.newsCard} />
                  </div>
                </Grid>
              ))}
              {newsSearch.hits.length > 4 && (
                <Hidden lgUp>
                  <Grid item xs lg={4}>
                    <div className={classes.sidewaysScrollItem}>
                      <CardAd />
                    </div>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>

          <Hidden mdDown>
            <Grid item lg={3}>
              <LgAd />
            </Grid>
          </Hidden>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          <Link href={NEWS_PATH(region)}>
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all news')}
              </Button>
            </a>
          </Link>
        </Grid>

        {/** FESTIVALS */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Upcoming festivals')}
        </Typography>

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Hidden mdDown>
            <Grid item lg={3}>
              <MdAd />
            </Grid>
          </Hidden>

          <Grid item xs lg={9}>
            <Grid
              container
              spacing={4}
              className={classes.sidewaysScrollContainer}
            >
              {festivalHits.slice(0, 3).map((festival) => (
                <Grid key={festival.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <FestivalCard festival={festival} size='small' />
                  </div>
                </Grid>
              ))}
              <Hidden lgUp>
                <Grid item xs lg={4}>
                  <div className={classes.sidewaysScrollItem}>
                    <CardAd />
                  </div>
                </Grid>
              </Hidden>
              {festivalHits.slice(3, 6).map((festival) => (
                <Grid key={festival.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <FestivalCard festival={festival} size='small' />
                  </div>
                </Grid>
              ))}
              {festivalHits.length > 4 && (
                <Hidden lgUp>
                  <Grid item xs lg={4}>
                    <div className={classes.sidewaysScrollItem}>
                      <CardAd />
                    </div>
                  </Grid>
                </Hidden>
              )}
              {festivalHits.slice(6, 8).map((festival) => (
                <Grid key={festival.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <FestivalCard festival={festival} size='small' />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          <Link href={FESTIVAL_LIST_PATH(region)}>
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all festivals')}
              </Button>
            </a>
          </Link>
        </Grid>

        {/** CONCERTSERIES */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Upcoming concert series')}
        </Typography>

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Grid item xs lg={9}>
            <Grid
              container
              spacing={4}
              className={classes.sidewaysScrollContainer}
            >
              {concertseriesSearch.hits.slice(0, 3).map((concertseries) => (
                <Grid key={concertseries.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <ConcertseriesCard
                      concertseries={concertseries}
                      size='small'
                    />
                  </div>
                </Grid>
              ))}

              <Hidden lgUp>
                <Grid item xs lg={4}>
                  <div className={classes.sidewaysScrollItem}>
                    <CardAd />
                  </div>
                </Grid>
              </Hidden>

              {concertseriesSearch.hits.slice(3, 6).map((concertseries) => (
                <Grid key={concertseries.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <ConcertseriesCard
                      concertseries={concertseries}
                      size='small'
                    />
                  </div>
                </Grid>
              ))}

              {concertseriesSearch.hits.length > 4 && (
                <Hidden lgUp>
                  <Grid item xs lg={4}>
                    <div className={classes.sidewaysScrollItem}>
                      <CardAd />
                    </div>
                  </Grid>
                </Hidden>
              )}

              {concertseriesSearch.hits.slice(6).map((concertseries) => (
                <Grid key={concertseries.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <ConcertseriesCard
                      concertseries={concertseries}
                      size='small'
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Hidden mdDown>
            <Grid item lg={3}>
              <MdAd />
            </Grid>
          </Hidden>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          <Link href={CONCERTSERIES_LIST_PATH(region)}>
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all concert series')}
              </Button>
            </a>
          </Link>
        </Grid>

        {/** ENSEMBLES */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Featured ensembles')}
        </Typography>

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Hidden mdDown>
            <Grid item lg={3}>
              <MdAd />
            </Grid>
          </Hidden>

          <Grid item xs lg={9}>
            <Grid
              container
              spacing={4}
              className={classes.sidewaysScrollContainer}
            >
              {ensembleSearch.hits.slice(0, 3).map((ensemble) => (
                <Grid key={ensemble.objectID} item xs={12} lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <EnsembleCard ensemble={ensemble} size='small' />
                  </div>
                </Grid>
              ))}

              <Hidden lgUp>
                <Grid item xs={12} lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <CardAd />
                  </div>
                </Grid>
              </Hidden>

              {ensembleSearch.hits.slice(3, 6).map((ensemble) => (
                <Grid key={ensemble.objectID} item xs={12} lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <EnsembleCard ensemble={ensemble} size='small' />
                  </div>
                </Grid>
              ))}

              {ensembleSearch.hits.length > 4 && (
                <Hidden lgUp>
                  <Grid item xs={12} lg={3}>
                    <div className={classes.sidewaysScrollItem}>
                      <CardAd />
                    </div>
                  </Grid>
                </Hidden>
              )}

              {ensembleSearch.hits.slice(6).map((ensemble) => (
                <Grid key={ensemble.objectID} item xs={12} lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <EnsembleCard ensemble={ensemble} size='small' />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          <Link href={ENSEMBLE_LIST_PATH(region)}>
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all ensembles')}
              </Button>
            </a>
          </Link>
        </Grid>

        {/** VENUES */}
        <Typography className={classes.title} variant='h1' component='h1'>
          {t('Featured venues')}
        </Typography>

        <Grid container spacing={4} className={classes.paddingBottom4}>
          <Grid item xs lg={9}>
            <Grid
              container
              spacing={4}
              className={classes.sidewaysScrollContainer}
            >
              {venueSearch.hits.slice(0, 3).map((venue) => (
                <Grid key={venue.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <VenueCard venue={venue} size='small' />
                  </div>
                </Grid>
              ))}

              <Hidden lgUp>
                <Grid item xs={12} lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <CardAd />
                  </div>
                </Grid>
              </Hidden>

              {venueSearch.hits.slice(3, 6).map((venue) => (
                <Grid key={venue.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <VenueCard venue={venue} size='small' />
                  </div>
                </Grid>
              ))}

              {venueSearch.hits.length > 4 && (
                <Hidden lgUp>
                  <Grid item xs={12} lg={3}>
                    <div className={classes.sidewaysScrollItem}>
                      <CardAd />
                    </div>
                  </Grid>
                </Hidden>
              )}

              {venueSearch.hits.slice(6).map((venue) => (
                <Grid key={venue.objectID} item xs lg={3}>
                  <div className={classes.sidewaysScrollItem}>
                    <VenueCard venue={venue} size='small' />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Hidden mdDown>
            <Grid item lg={3}>
              <MdAd />
            </Grid>
          </Hidden>
        </Grid>

        <Grid
          container
          justifyContent='center'
          className={classes.marginBottom8}
        >
          <Link href={VENUE_LIST_PATH(region)}>
            <a>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {t('See all venues')}
              </Button>
            </a>
          </Link>
        </Grid>

        <Newsletter />
        <ScrollToTopButton />
        <FrontpagePopup />
      </Container>
    </>
  )
}

export default FrontPage
