import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { Concertseries } from '../../types/types'
import { getAllDocuments, Indexed } from '../meilisearch'
import { generateConcertSeriesSlug } from '../slugs'

export const useConcertseriesSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<Concertseries>(filters, 'concertseries', query, numHits)

export const generateConcertseriesId = (
  firestore: ExtendedFirestoreInstance
): string => firestore.collection('concertseries').doc().id

export const createConcertseries = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Concertseries, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  concertseriesId: string
): Promise<void> => {
  const slug = generateConcertSeriesSlug(data.name)
  await firestore
    .collection('concertseries')
    .doc(concertseriesId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getConcertseriesDoc = async (
  firestore: ExtendedFirestoreInstance,
  concertseriesId: string
): Promise<firebase.firestore.DocumentSnapshot<Concertseries>> => {
  const concertseriesDoc = await firestore
    .collection('concertseries')
    .doc(concertseriesId)
    .get()
  return concertseriesDoc as firebase.firestore.DocumentSnapshot<Concertseries>
}

export const updateConcertseries = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<Concertseries>,
  concertseriesId: string
): Promise<void> => {
  await firestore
    .collection('concertseries')
    .doc(concertseriesId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteConcertseries = async (
  firestore: ExtendedFirestoreInstance,
  concertseriesId: string
): Promise<void> => {
  await firestore.collection('concertseries').doc(concertseriesId).update({
    isDeleted: true
  })
}

export const getAllPublicConcertseries = async () => {
  const concertseries = await getAllDocuments<
    Indexed<Concertseries> & { countryCode: string }
  >('concertseries')

  return concertseries.filter(
    (concertseries) => concertseries.isDeleted === false
  )
}
