import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { Venue } from '../../types/types'
import { generateVenueSlug } from '../slugs'
import { getAllDocuments, Indexed } from '../meilisearch'

export const useVenueSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<Venue>(filters, 'venues', query, numHits)

export const generateVenueId = (firestore: ExtendedFirestoreInstance): string =>
  firestore.collection('venues').doc().id

export const createVenue = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Venue, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  venueId: string
): Promise<void> => {
  const slug = generateVenueSlug(data.name, data.location.city)
  await firestore
    .collection('venues')
    .doc(venueId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getVenueDoc = async (
  firestore: ExtendedFirestoreInstance,
  venueId: string
): Promise<firebase.firestore.DocumentSnapshot<Venue>> => {
  const venueDoc = await firestore.collection('venues').doc(venueId).get()
  return venueDoc as firebase.firestore.DocumentSnapshot<Venue>
}

export const updateVenue = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<Venue>,
  venueId: string
): Promise<void> => {
  await firestore
    .collection('venues')
    .doc(venueId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteVenue = async (
  firestore: ExtendedFirestoreInstance,
  venueId: string
): Promise<void> => {
  await firestore.collection('venues').doc(venueId).update({
    isDeleted: true
  })
}

export const getAllPublicVenues = async () => {
  const venues = await getAllDocuments<
    Indexed<Venue> & { countryCode: string }
  >('venues')

  return venues.filter(
    (venue) => venue.isDeleted === false && venue.isOverview === true
  )
}
