import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'next-i18next'
import { NEWS_POST_COLOURS } from '../constants/newsPostTypes'

const useNewsStyles = makeStyles(() => {
  return Object.fromEntries(
    Object.entries(NEWS_POST_COLOURS).map(([type, color]) => [
      type,
      {
        color: color,
        borderColor: color,
        '&.selected': {
          color: 'white',
          border: '2px solid',
          borderColor: color,
          backgroundColor: color
        }
      }
    ])
  )
})

const useStyles = makeStyles((theme) => ({
  chip: {
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '24px',
    textTransform: 'capitalize'
  }
}))

type NewsTypeChipProps = {
  type: string
}

const NewsTypeChip: React.FC<NewsTypeChipProps> = ({ type }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const newsClasses = useNewsStyles()

  return (
    <Chip
      className={`${newsClasses[type]} ${classes.chip}`}
      label={t(type)}
      variant='outlined'
    />
  )
}

export default NewsTypeChip
