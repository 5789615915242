import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import { CONCERTSERIES_DETAILS_PATH } from '../constants/paths'
import Link from 'next/link'
import { Concertseries } from '../types/types'
import { getThumbnailUrl } from '../utils/thumbnail'
import { useRegion } from '../hooks/useRegion'

const useStyles = makeStyles(() => ({
  media: {
    height: '200px'
  },
  cardRoot: {
    minHeight: '100%',
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    height: 90,
    fontWeight: 'bold',
    color: '#081359',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    lineHeight: 1.4
  },
  citySingle: {
    color: '#8187AD'
  }
}))

type ConcertseriesCardProps = {
  concertseries: Concertseries
  size?: 'small' | 'medium'
}

const ConcertseriesCard: React.VFC<ConcertseriesCardProps> = ({
  concertseries,
  size = 'medium'
}) => {
  const classes = useStyles()
  const region = useRegion()

  return (
    <Link
      href={CONCERTSERIES_DETAILS_PATH(region, concertseries.slug)}
      passHref
    >
      <a>
        <Card className={classes.cardRoot}>
          <CardMedia
            className={classes.media}
            image={getThumbnailUrl(concertseries.coverUrl)}
            title={concertseries.name}
          />
          <CardContent>
            <Typography className={classes.title} variant='h6'>
              {concertseries.name}
            </Typography>
            {size !== 'small' && (
              <Typography className={classes.citySingle} variant='h6'>
                {concertseries?.location?.city || ''}
              </Typography>
            )}
          </CardContent>
        </Card>
      </a>
    </Link>
  )
}

export default ConcertseriesCard
