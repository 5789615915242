import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@material-ui/core'
import { Ensemble } from '../types/types'
import { getThumbnailUrl } from '../utils/thumbnail'
import { ENSEMBLE_DETAILS_PATH } from '../constants/paths'
import { useRegion } from '../hooks/useRegion'
import Link from 'next/link'

const useStyles = makeStyles(() => ({
  media: {
    height: '200px'
  },
  cardRoot: {
    minHeight: '100%',
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    height: 90,
    fontWeight: 'bold',
    color: '#081359',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    lineHeight: 1.4
  }
}))

interface EnsembleCardProps {
  ensemble: Ensemble
  size?: 'small' | 'medium'
}

const EnsembleCard: React.FC<EnsembleCardProps> = ({ ensemble }) => {
  const classes = useStyles()
  const region = useRegion()

  return (
    <Link href={ENSEMBLE_DETAILS_PATH(region, ensemble.slug)} passHref>
      <a>
        <Card className={classes.cardRoot}>
          <CardMedia
            className={classes.media}
            image={getThumbnailUrl(ensemble.photoUrl)}
            title={ensemble.name}
          />
          <CardContent>
            <Typography className={classes.title} variant='h6'>
              {ensemble.name}
            </Typography>
          </CardContent>
        </Card>
      </a>
    </Link>
  )
}

export default EnsembleCard
